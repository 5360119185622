import { Controller } from 'stimulus'
import { Turbo } from '@hotwired/turbo-rails'
export default class extends Controller {
  static targets = ["item", "position"]
  static values = {
    url: String,
    shiftUrl: String,
    indexUrl: String
  }

  connect() {
    console.log('up-down connected')    
  }

  savePosition() {
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let url = this.urlValue
    let itemIds = this.itemTargets.map((x)=>x.getAttribute("data-item-id"))

    fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        ids: itemIds
      })
    })
  }

  shiftPosition(page_id, dir) {
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let url = this.shiftUrlValue
    let itemIds = this.itemTargets.map((x)=>x.getAttribute("data-item-id"))

    fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        page_id: page_id,
        dir: dir
      })      
    }).then(response=>response.json()).then(json=>{ 
      if (json.current_page_num != json.next_page_num){
        Turbo.visit(`${this.indexUrlValue}?page=${json.next_page_num}`)
      }else{
        for (let i=0;i<this.positionTargets.length;i++){
          this.positionTargets[i].innerHTML = ((json.next_page_num - 1)*json.per_page+i+1).toString()
        }        
      }
    })
  }

  moveDown(e) {    
    e.preventDefault()
    e.stopPropagation()
    let itemId = e.currentTarget.getAttribute('data-item-id')
    let curIndex = this.itemTargets.findIndex((x)=> x.getAttribute('data-item-id') == itemId)
    let curItem = this.itemTargets[curIndex]
 
    if (curItem.nextElementSibling) {
      curItem.parentNode.insertBefore(curItem.nextElementSibling, curItem)
      this.shiftPosition(itemId, 'down')
    }else{
      this.shiftPosition(itemId, 'down')
    }
  }

  moveUp(e) {    
    e.preventDefault()
    e.stopPropagation()
    let itemId = e.currentTarget.getAttribute('data-item-id')
    let curIndex = this.itemTargets.findIndex((x)=> x.getAttribute('data-item-id') == itemId)
    let curItem = this.itemTargets[curIndex]
    
    if (curItem.previousElementSibling.querySelector('td')) {
      curItem.parentNode.insertBefore(curItem, curItem.previousElementSibling)
      this.shiftPosition(itemId, 'up')
    }else{
      this.shiftPosition(itemId, 'up')
    }
  }
}