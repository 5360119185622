<template>
  Hello
</template>

<script>
export default {
  props: ['json'],
  setup(props){
    return {
      
    }
  }
}
</script>