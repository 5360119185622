import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["textfield"]
  static detail = null
  static event_type = ""


  connect(){
    console.log('form_popup connected')
  }

  show(e){    
    e.preventDefault()    
    this.element.classList.remove("hidden")
    this.detail = e.detail
    this.event_type = e.type
    
  }

  close(e){    
    e.preventDefault()    
    this.element.classList.add("hidden")
  }

  confirmed(e){
    console.log(this.event_type.replace("ask-",""))
    e.preventDefault()
    let hash = this.detail
    let formData = new FormData(this.element.querySelector("form"))
    for (let pair of formData.entries()){
      hash[pair[0]] = pair[1]
    }

    this.close(e)
    let event = new CustomEvent(this.event_type.replace("ask-",""), {detail: hash})
    window.dispatchEvent(event)
  }
}