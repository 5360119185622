import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["layer"]
  static detail = null
  static event_type = ""

  connect(){
    console.log('delete_popup connected')
  }

  show(e){
    console.log('delete_popup show')
    e.preventDefault()    
    this.element.classList.remove("hidden")
    this.detail = e.detail
    this.event_type = e.type
  }

  close(e){
    e.preventDefault()    
    this.element.classList.add("hidden")
  }

  confirmed(e){
    e.preventDefault()
    this.close(e)    
    let event = new CustomEvent(this.event_type.replace("ask-",""), {detail: this.detail})
    window.dispatchEvent(event)
  }
}