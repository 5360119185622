import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["open", "close", "nav"]
  connect(){
    console.log('nav_menu connected')    
  }

  showMenu(){
    this.navTarget.classList.remove('hidden')
    this.openTarget.classList.add('hidden')
    this.closeTarget.classList.remove('hidden')
  }

  closeMenu(){
    this.navTarget.classList.add('hidden')
    this.openTarget.classList.remove('hidden')
    this.closeTarget.classList.add('hidden')
  }
}