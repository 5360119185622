// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@hotwired/turbo-rails")

// import * as Turbo from "@hotwired/turbo";

import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "stylesheets/application"
import 'controllers'

import { createApp } from 'vue'

ActiveStorage.start()

import Hello from '../components/Hello.vue'
import ImageUploader from '../components/ImageUploader.vue'

const components = {Hello, ImageUploader}

document.addEventListener("turbo:load", (e) => {
  console.log('load turbo')
  document.querySelectorAll('[data-component]').forEach((node) => {
    let json = ""
    if (node.dataset.json != undefined){
      json = node.dataset.json
    }
    createApp(components[node.dataset.component],{json: json}).mount(node)
  })
})



require("trix")
require("@rails/actiontext")