import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
export default class extends Controller {
  static targets = ["item"]

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  highlight(event) {
    event.target.classList.add("hovered-item")
  }

  noHighlight(event) {
    event.target.classList.remove("hovered-item")
  }

  end(event) {
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let url = this.data.get('url')
    let itemIds = this.itemTargets.map((x)=>x.getAttribute("data-id"))

    fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        ids: itemIds
      })
    })
  }
}