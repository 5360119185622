import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["province", "city"]
  connect(){
    console.log('admin_order connected')
  }

  askAddShippingCost(e){
    e.preventDefault()
    let order_id = this.data.get('order-id')
    let event = new CustomEvent('ask-add-shipping-cost', {detail: {order_id: order_id}})
    window.dispatchEvent(event)
  }

  askAddLineItem(e){
    e.preventDefault()
    let order_id = this.data.get('order-id')
    let event = new CustomEvent('ask-add-line-item', {detail: {order_id: order_id}})
    window.dispatchEvent(event)
  }

  askDeleteLineItem(e){
    e.preventDefault()
    let order_id = this.data.get('order-id')
    let line_item_id = e.currentTarget.getAttribute('data-line-item-id')
    let event = new CustomEvent('ask-delete-line-item', {detail: {order_id: order_id, line_item_id: line_item_id}})
    window.dispatchEvent(event)
  }

  addLineItem(e){
    e.preventDefault()
    let order_id = e.detail.order_id
    let product_id = e.detail.product_id
    let qty = e.detail.qty

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(`/orders/${order_id}/line_items`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({line_item: {product_id: product_id, quantity: qty}})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('lines-updated', {detail: {url: `/orders/${order_id}/line_items`, method: 'get'}})
        window.dispatchEvent(event)
      }
    })   
  }

  addShippingCost(e){
    e.preventDefault()
    let order_id = e.detail.order_id
    let price = e.detail['textfield-value']

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(`/orders/${order_id}/line_items/create_shipping`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({price: price})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('lines-updated', {detail: {url: `/orders/${order_id}/line_items`, method: 'get'}})
        window.dispatchEvent(event)
      }
    })
  }

  deleteLineItem(e){
    e.preventDefault()
    let order_id = e.detail.order_id
    let line_item_id = e.detail.line_item_id
    let price = e.detail['textfield-value']

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(`/orders/${order_id}/line_items/${line_item_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('lines-updated', {detail: {url: `/orders/${order_id}/line_items`, method: 'get'}})
        window.dispatchEvent(event)
      }
    })
  }

  refreshCity(e){
    let province_id = this.provinceTarget.value
    let event = new CustomEvent('city-refresh', {detail: {url: `/orders/load_cities?province_id=${province_id}`, method: 'get'}})
    window.dispatchEvent(event)
  }
}