import * as Turbo from "@hotwired/turbo"
import { Controller } from 'stimulus'

export default class extends Controller {
  connect(){
    console.log('product_list connected')
  }

  askDeleteProduct(e){
    let product_id = e.currentTarget.getAttribute('data-product-id') 
    let event = new CustomEvent('ask-product-delete', {detail: {product_id: product_id}})
    window.dispatchEvent(event)
  }

  deleteProduct(e){
    let product_id = e.detail.product_id
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(`/products/${product_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        // let event = new CustomEvent('product-deleted', {detail: {url: `/products`, method: 'get'}})
        // window.dispatchEvent(event)
        Turbo.visit('/products')
      }
    })
  }

  sendPush(e){
    let product_id = e.currentTarget.getAttribute('data-product-id')
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(`/products/${product_id}/send_push`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        alert('terkirim')
      }
    })
    
  }
}