import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fileProgressList", "imageProgressList","videoUrl"]

  connect(){
    console.log('product_wizard connected')
  }

  prev(e){
    e.preventDefault()
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    
    fetch(`/products/${product_id}`, {
      method: 'PATCH',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({button: 'prev'})
    }).then(resp => resp.json()).then((json)=> {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })    
  }

  jumpTo(e){
    e.preventDefault()
    let product_id = this.data.get('product-id')
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let step = e.target.getAttribute('data-jump-to')
    fetch(`/products/${product_id}/jump_step`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({step: step})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }

  deletePhoto(e){
    e.preventDefault()
    let photo_id = e.target.getAttribute('data-photo-id')
    let product_id = this.data.get('product-id')
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(`/products/${product_id}/product_images/${photo_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_photos`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }

  deleteFile(e){
    e.preventDefault()
    let file_id = e.target.getAttribute('data-file-id')
    let product_id = this.data.get('product-id')
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(`/products/${product_id}/product_files/${file_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_product_files`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }

  updatePhoto(e){
    e.preventDefault()
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    let photo_id = e.target.getAttribute('data-photo-id')

    fetch(`/products/${product_id}/product_images/${photo_id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({product_image: {name: e.target.value}})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        
      }
    })
  }



  updateFile(e){
    e.preventDefault()
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    let file_id = e.target.getAttribute('data-file-id')
    let name = e.target.getAttribute('name')

    let params = {product_image: {}}
    if (name == 'name'){
      params.product_image['name'] = e.target.value
    }else if (name == 'is_key_required'){
      params.product_image['is_key_required'] = e.target.checked
    }

    fetch(`/products/${product_id}/product_files/${file_id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(params)
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        
      }
    })
  }  
  itemStartDrag(e){
    let photo_id = e.target.getAttribute('data-photo-id')
    e.dataTransfer.dropEffect = 'move'
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('photo-id', photo_id)
  }

  fileStartDrag(e){
    let file_id = e.target.getAttribute('data-file-id')
    e.dataTransfer.dropEffect = 'move'
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('file-id', file_id)
  }

  itemDropped(e){
    let fromId = parseInt(e.dataTransfer.getData('photo-id'))
    let newId = e.target.getAttribute('data-photo-id')

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    

    fetch(`/products/${product_id}/product_images/swap_photo_position`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ids: [fromId, newId]})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }

  

  fileDropped(e){
    let fromId = parseInt(e.dataTransfer.getData('file-id'))
    let newId = e.currentTarget.getAttribute('data-file-id')

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    

    fetch(`/products/${product_id}/product_files/swap_file_position`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ids: [fromId, newId]})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }

  prevent(e){
    e.preventDefault()
  }

  addPhotoUsingXhr(e){
    e.preventDefault()

    const fileTemplate = function(json){      
      return `
      <div id="${json.uid}">
        ${json.file.name} uploading...
      </div>`  
    }

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    let file = e.target.files[0]
    
    let json = {
      uid: new Date().getTime(),
      file: file,
      status: 'ready'
    }

    this.imageProgressListTarget.innerHTML = this.imageProgressListTarget.innerHTML + fileTemplate(json)

    let form = new FormData()
    form.append("photo", file)
    form.append("authenticity_token", token)

    let request = new XMLHttpRequest()
    request.open('POST', `/products/${product_id}/product_images`)

    request.upload.addEventListener('progress', function(e){
      let percent_completed = (e.loaded / e.total)*100;
	    console.log(percent_completed)

      let el = document.getElementById(json.uid)
      el.innerHTML = `${json.file.name} uploading ${e.loaded} / ${e.total}`
    })

    request.addEventListener('load', function(e) {
      // HTTP status message (200, 404 etc)
      console.log(request.status);
    
      // request.response holds response from the server
      console.log(request.response);
      let el = document.getElementById(json.uid)
      el.remove()
      let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_photos`, method: 'post'}})
      window.dispatchEvent(event)
    });
    request.send(form)
    e.target.value = ""
  } 

  addFileUsingXhr(e){
    e.preventDefault()

    const fileTemplate = function(json){      
      return `
      <div id="${json.uid}">
        ${json.file.name} uploading...
      </div>`  
    }

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    let file = e.target.files[0]
    
    let json = {
      uid: new Date().getTime(),
      file: file,
      status: 'ready'
    }

    this.fileProgressListTarget.innerHTML = this.fileProgressListTarget.innerHTML + fileTemplate(json)

    let form = new FormData()
    form.append("file", file)
    form.append("authenticity_token", token)

    let request = new XMLHttpRequest()
    request.open('POST', `/products/${product_id}/product_files`)

    request.upload.addEventListener('progress', function(e){
      let percent_completed = (e.loaded / e.total)*100;
	    console.log(percent_completed)

      let el = document.getElementById(json.uid)
      el.innerHTML = `${json.file.name} uploading ${e.loaded} / ${e.total}`
    })

    request.addEventListener('load', function(e) {
      // HTTP status message (200, 404 etc)
      console.log(request.status);
    
      // request.response holds response from the server
      console.log(request.response);
      let el = document.getElementById(json.uid)
      el.remove()
      let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_product_files`, method: 'post'}})
      window.dispatchEvent(event)
    });
    request.send(form)
    e.target.value = ""
  }

  deleteVideoUrl(e){
    e.preventDefault()
    let embed_id = e.target.getAttribute('data-embed-id')
    let product_id = this.data.get('product-id')
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(`/products/${product_id}/embeded_videos/${embed_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }


  addVideoUrl(e){
    e.preventDefault()
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let url = this.videoUrlTarget.value
    let product_id = this.data.get('product-id')    

    fetch(`/products/${product_id}/embeded_videos`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({embeded_video: {embed_code: url}})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }
}