import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["cards"]
  connect(){
    console.log('product_show connected')
  }

  changeCard(e){
    e.preventDefault();
    let index = e.currentTarget.getAttribute('data-index')
    console.log(index)
    //debugger
    let divs = this.cardsTarget.querySelectorAll('div')
    divs.forEach((div)=>div.classList.add('hidden'))
    divs[parseInt(index)].classList.remove('hidden')
  }

  askProductKey(e){
    e.preventDefault()
    let product_key_id = e.currentTarget.getAttribute('data-product-key-id')
    let event = new CustomEvent('ask-product-key-download', {detail: {product_key_id: product_key_id}})
    window.dispatchEvent(event)
  }

  downloadFile(e){
    e.preventDefault()
    let product_key_id = e.detail.product_key_id
    let download_key = e.detail['textfield-value']
    
    location.href = `/home/download_product_file/${product_key_id}/file?download_key=${download_key}`
  }
}