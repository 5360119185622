import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ['productKeyForm', 'name', 'downloadKey']
  
  connect(){
    console.log('product_key_list connected')
  }

  showProductKeyForm(e){
    e.preventDefault()
    this.productKeyFormTarget.classList.remove('hidden')
  }
  hideProductKeyForm(e){
    e.preventDefault()
    this.productKeyFormTarget.classList.add('hidden')
  }

  createProductKey(e){
    e.preventDefault()
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')

    fetch(`/products/${product_id}/product_keys`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        product_key: {name: this.nameTarget.value, download_key: this.downloadKeyTarget.value}
      })
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }

  deleteProductKey(e){
    e.preventDefault()
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let product_id = this.data.get('product-id')
    let product_key_id = e.currentTarget.getAttribute('data-product-key-id')

    fetch(`/products/${product_id}/product_keys/${product_key_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('product-step-updated', {detail: {url: `/products/${product_id}/load_step`, method: 'post'}})
        window.dispatchEvent(event)
      }
    })
  }
}