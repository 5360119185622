<template>
  <div>
  Image Uploader<br/>
  <input type="file" @change="inserted($event)" ref="uploader"/><br/>
  <ul>
    <li v-for="(image,index) in state.images" v-bind:key="index">
      {{index}} {{image.filename}} {{image.status}} {{image.loaded}}/{{image.total}}
      <input type="hidden" name="images[]" v-if="!image.id" :value="image.signed_id"/>
      <img :src="image.data" class="w-80"/>
      <a href="#" data-turbo="false" @click="deleteImage(index)">X</a>
    </li>
  </ul>
  
  <input v-for="image in state.deleted_images" type="hidden" name="deleted_images[]" :value="image" v-bind:key="image"/>
  
  <br/>{{state}}
  </div>
</template>

<script>
import {reactive} from '@vue/reactivity'
import { DirectUpload } from "@rails/activestorage"

export default {
  props: ['json'],
  setup(props){
    let hash = JSON.parse(props.json)
    let state = reactive({
      images: hash.images,
      deleted_images: []
    })

    const inserted = (e)=>{
      console.log(e)
      
      let file = e.target.files[0]
      let reader = new FileReader()   
      reader.readAsDataURL(e.target.files[0])
      
      reader.onload = (x) => {
        let data = x.target.result
        
        let params = {
          uid: new Date().getTime(),
          filename: file.name,
          filesize: file.size,
          content_type: file.type,
          data: data        
        }        
        
        state.images.push(params)
      }                     
      
      e.target.value = ""
    }

    const deleteImage = (index) => {
      if (state.images[index].id){
        state.deleted_images.push(state.images[index].id)
      }

      state.images.splice(index, 1)
    }

    const createProduct = () => {
      let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      fetch("/products", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        body: JSON.stringify({
          
        })
      })
    }

    

    
    return {
      state: state,
      inserted: inserted,
      deleteImage: deleteImage,
      
    }
  }
}
</script>
