import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["message"]
  connect(){
    console.log('flash_notice connected')
  }

  show(e){
    e.preventDefault()
    this.messageTarget.innerHTML = e.detail.message
    this.element.classList.remove('hidden')

    setTimeout(()=>{
      this.element.classList.add("hidden")
    }, 3000)
  }
}