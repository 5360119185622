import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["province", "city"]
  connect(){
    console.log('cart connected')
  }

  addItem(e){
    e.preventDefault()
    let product_id = this.data.get('product-id')
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(`/cart/add/${product_id}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('cart-updated', {detail: {url: `/cart`, method: 'get'}})
        window.dispatchEvent(event)

        event = new CustomEvent('notice-available', {detail: {message:`item berhasil ditambahkan ke keranjang belanja`}})
        window.dispatchEvent(event)
      }
    })
  }

  deleteLineItem(e){
    e.preventDefault()
    let product_id = this.data.get('product-id')
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let line_item_id = e.currentTarget.getAttribute('data-line-item-id')

    fetch(`/cart/delete_line_item/${line_item_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({})
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('cart-updated', {detail: {url: `/cart`, method: 'get'}})
        window.dispatchEvent(event)

        event = new CustomEvent('notice-available', {detail: {message:`item berhasil diupdate`}})
        window.dispatchEvent(event)
      }
    })
  }

  refreshCheckout(e){
    e.preventDefault()
    let city_id = this.cityTarget.value
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let event = new CustomEvent('address-changed', {detail: {url: `/cart/checkout?city_id=${city_id}`, method: 'get'}})
    window.dispatchEvent(event)
    return
    fetch(`/cart/checkout?city_id=${city_id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    }).then(resp => resp.json()).then((json) => {
      if (json.status == 'ok'){
        let event = new CustomEvent('address-changed', {detail: {url: `/cart/checkout?city_id=${city_id}`, method: 'get'}})
        window.dispatchEvent(event)
      }
    })
  }

  refreshCity(e){
    let province_id = this.provinceTarget.value
    let event = new CustomEvent('city-refresh', {detail: {url: `/cart/load_cities?province_id=${province_id}`, method: 'get'}})
    window.dispatchEvent(event)
  }
}