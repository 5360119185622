import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["textfield"]
  static detail = null
  static event_type = ""


  connect(){
    console.log('textfield_popup connected')
  }

  show(e){    
    e.preventDefault()    
    this.element.classList.remove("hidden")
    this.detail = e.detail
    this.event_type = e.type
    
  }

  close(e){    
    e.preventDefault()    
    this.element.classList.add("hidden")
  }

  confirmed(e){
    console.log(this.event_type.replace("ask-",""))
    e.preventDefault()
    
    let hash = this.detail
    
    hash['textfield-value'] = this.textfieldTarget.value
    if (this.data.get("validate") == "integer"){
      if (!this.textfieldTarget.value.match(/^[0-9]+$/)){
        return
      }
    }else if (this.data.get("validate") == "present"){
      if (this.textfieldTarget.value == ''){
        return
      }
    }
    this.close(e)
    let event = new CustomEvent(this.event_type.replace("ask-",""), {detail: hash})
    window.dispatchEvent(event)
  }
}